import React, { useEffect } from "react"
import Seo from "../components/seo"
import styled from "styled-components"
import Redirect from "../icons/reroute/noun-redirect.svg"
import { Link } from "gatsby"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"
import { navigate } from "gatsby"
const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  overflow: hidden;
  h1 {
    /* color: rgb(228, 67, 49); */
  }

  .logo-icon {
    margin-bottom: 24px;
    width: 258px;
    height: 258px;
    height: auto;
  }
  path {
    fill: rgb(228, 67, 49);
  }
`

const Submitreview = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const lang = urlParams.get("lang")
    const product = urlParams.get("product")

    navigate("https://slashdot.org/software/p/PDF-Pro-10/reviews/new")
  }, [])
  return (
    <>
      <Seo title="Submit Review" />
      <Style>
        <Redirect className="logo-icon" />
        <h1>Redirecting...</h1>
      </Style>
    </>
  )
}

export default Submitreview
